import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { transientOptions } from "@product/scmp-sdk";

import { BaseLink } from "scmp-app/components/common/base-link";
import { DynamicHeaderOnelineMenuContainer } from "scmp-app/components/header/dynamics";
import type { LogoProps } from "scmp-app/components/header/styles";
import { cssVariableValues, setFullViewportWidthStyles } from "scmp-app/lib/styles";
import { setResponsiveVariantStyles } from "scmp-app/lib/styles/responsive";

import LogoFlag from "./icon-logo-style-flag.svg";
import LogoText from "./icon-logo-style-text.svg";
import Logo from "./icon-logo-style.svg";

export const StyledLogoFlag = styled(LogoFlag)`
  display: inline-block;

  inline-size: 33px;
  block-size: 24px;
`;

export const StyledLogoText = styled(LogoText)`
  display: inline-block;

  inline-size: 42px;
  block-size: 28px;
  margin-inline-start: 14px;

  transform: translateY(2px);

  ${props => props.theme.breakpoints.up("desktop")} {
    margin-inline-start: 24px;
  }
`;

type ContainerProps = {
  $isArticlePage?: boolean;
};
export const Container = styled.div<ContainerProps>`
  ${props => {
    if (props.$isArticlePage) {
      return css`
        ${props.theme.breakpoints.only("mobile")} {
          ${StyledLogoFlag} {
            inline-size: 20px;
            block-size: 16px;
          }
          ${StyledLogoText} {
            inline-size: 36px;
            block-size: 22px;
          }
        }
      `;
    }
  }}
`;

export const HeaderMainLogoContainer = styled.section`
  display: none;
  justify-content: center;
  align-items: flex-end;

  block-size: auto;

  ${props => props.theme.breakpoints.up("tablet")} {
    display: flex;

    padding-block-start: 48px;
  }

  ${props => props.theme.breakpoints.up("desktop")} {
    padding-block-start: 64px;
  }
`;
export const HeaderLogo = styled(Logo)`
  display: inline-block;

  inline-size: 120px;
  block-size: 75px;
  margin-block-end: 10px;

  ${props => props.theme.breakpoints.up("tablet")} {
    inline-size: 209px;
    block-size: 132px;
  }
  ${props => props.theme.breakpoints.up("desktop")} {
    inline-size: 278px;
    block-size: 176px;
  }
`;
export const HeaderContainer = styled.div`
  display: grid;
  grid-template-areas: "logo onelineMenu actionButtonContainer";
  grid-template-columns: 1fr 2fr 1fr;
  place-items: center center;

  block-size: ${cssVariableValues.appBarHeight};
  box-sizing: border-box;

  background-color: transparent;
`;

export const LogoTextLink = styled(BaseLink)``;

export const StyledLogoContainer = styled("div", { ...transientOptions })<LogoProps>`
  grid-area: logo;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  justify-self: start;
  align-items: center;

  ${StyledLogoText} {
    opacity: 0;
  }

  ${props =>
    setResponsiveVariantStyles(
      props.$responsiveVariants,
      variant => css`
        ${StyledLogoText} {
          transition: opacity 0.3s ease-in-out;

          visibility: ${variant === "full" ? "visible" : "hidden"};
          opacity: ${variant === "full" ? "1" : "0"};
          inline-size: ${variant === "full" ? "auto" : "0"};
        }

        ${LogoTextLink} {
          display: ${variant === "full" ? "block" : "none"};
        }
      `,
    )}

  /* Remarks:
    Workaround for small mobile which designer is not accept to use mobile down
  */
  ${props => props.theme.breakpoints.down(361)} {
    ${StyledLogoText} {
      display: none;
    }
  }
`;

export const StyledDynamicHeaderOnelineMenuContainer = styled(DynamicHeaderOnelineMenuContainer)`
  display: none;

  ${props => props.theme.breakpoints.up("desktop")} {
    display: flex;

    margin-inline-start: 0;
  }
`;

export const ActionButtonContainer = styled.div`
  grid-area: actionButtonContainer;

  display: flex;
  justify-self: end;
  align-items: center;
`;

type StyleContentHeaderProps = {
  $isFullWidth?: boolean;
};
export const StyleContentHeaderDivider = styled.hr<StyleContentHeaderProps>`
  display: none;
  ${props => props.theme.breakpoints.up("tablet")} {
    ${setFullViewportWidthStyles}
    position: absolute;
    inset-block-end: -7px;

    display: block;

    border: none;
    border-block-end: 1px solid #222222;
  }

  ${props => props.theme.breakpoints.up("largeDesktop")} {
    inset-inline-start: 50%;

    margin-inline: 0;

    transform: translateX(-50%);
  }
`;

type SectionStyleHeaderContainerProps = {
  $isSetMaxWidth?: boolean;
};
export const SectionStyleHeaderContainer = styled.div<SectionStyleHeaderContainerProps>`
  ${props => {
    if (props.$isSetMaxWidth) {
      return css`
        ${Container} {
          ${props.theme.breakpoints.up("desktop")} {
            max-inline-size: 1440px;
            margin-inline: auto;
          }
        }

        ${StyleContentHeaderDivider} {
          ${props.theme.breakpoints.up("tablet")} {
            max-inline-size: 1440px;
          }
        }
      `;
    }
  }}
`;
